import { CxOneVersion } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  MidtermRoutes,
  RenewalRoutes,
  midtermRoutesInfo,
  preFormRoutes,
  renewalRoutesInfo,
  utilityRoutes,
} from "../../routing/routes.config";
import useApiClient from "../useApiClient";
import useHasValidSession from "../useHasValidSession";

export const webChatDisallowPages = [
  ...utilityRoutes,
  ...preFormRoutes,
  renewalRoutesInfo[RenewalRoutes.Confirmation],
  midtermRoutesInfo[MidtermRoutes.Confirmation],
].map((route) => route.path.toLowerCase());

export const useGetWebChatInformation = () => {
  const client = useApiClient();
  const location = useLocation();
  const hasValidSession = useHasValidSession();

  const [crmId, setCrmId] = useState("");
  const [pointOfContactId, setPointOfContactId] = useState("");
  const [shouldRenderWebChat, setShouldRenderWebChat] = useState(false);
  const [cxOneVersion, setCxOneVersion] = useState(CxOneVersion.Agent);

  const currentLocation = location.pathname.toLowerCase();
  const shouldGetWebChatInformation = hasValidSession && !webChatDisallowPages?.includes(currentLocation);

  useEffect(() => {
    let isCancelled = false;

    const getWebChatId = async () => {
      try {
        if (!isCancelled) {
          if (shouldGetWebChatInformation) {
            const response = await client.getWebChatContactId();

            setPointOfContactId(response.result.pointOfContactId ?? "");
            setCrmId(response.result.crmId ?? "");
            setShouldRenderWebChat(true);
            setCxOneVersion(response.result.useWebChatCxOneAgent ? CxOneVersion.Agent : CxOneVersion.Max);
          } else {
            setShouldRenderWebChat(false);
          }
        }
      } catch {
        setPointOfContactId("");
        setCrmId("");
        setShouldRenderWebChat(false);
      }
    };

    getWebChatId();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, shouldGetWebChatInformation]);
  // CurrentLocation is a required dep otherwise will not check agent availability when user navigates from page to page

  return { pointOfContactId, crmId, shouldRender: shouldRenderWebChat, cxOneVersion: cxOneVersion };
};

export default useGetWebChatInformation;
